import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

// netlify-templates/gatsby-starter-netlify-cms/blob/master/src/components/PreviewCompatibleImage.js
const PreviewCompatibleImage = ({ imageInfo, className }) => {
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        className={className}
        fluid={image.childImageSharp.fluid}
        alt={alt}
      />
    )
  }

  if (!!childImageSharp) {
    return <Img className={className} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    // FIXME: Preview looks weird when the image lacks a maxWidth. I tried
    // supplying one with the style prop, but then that would conflict with one
    // passed via classNames. Could use some utility to merge classes here. But
    // for now, wrapping it in a div makes it behave much like gatsby-image
    return (
      <div className={className}>
        <img
          style={{ maxWidth: '100%', borderRadius: '2%' }}
          src={image}
          alt={alt}
        />
      </div>
    )

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  }).isRequired,
  className: PropTypes.string,
}

export default PreviewCompatibleImage
